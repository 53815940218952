import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LogPageLoad = () => {
  const location = useLocation(); // Detects route changes

  useEffect(() => {
    const logVisit = async () => {
      try {
        await fetch("/blackbox-api/ui-logger/log-page-load", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            page: location.pathname,
            referrer: document.referrer,
          }),
        });
      } catch (error) {
        console.error("Error logging page load:", error);
      }
    };

    logVisit();
  }, [location]); // Runs every time the route changes

  return null;
};

export default LogPageLoad;
